import { userConsts } from '../static/consts'

export const setAuthAnswer = (payload) => ({type: userConsts.SET_AUTH_ANSWER, payload})
export const setUserData = (payload) => ({type: userConsts.SET_USER_DATA, payload})
export const updateUser = (payload) => ({type: userConsts.UPDATE_USER_DATA, payload})
export const getAuthSaga = (dataForm) => ({type: userConsts.SAGA_AUTH, dataForm})
export const updateSaga = (values) => ({type: userConsts.SAGA_UPDATE, values})
export const exitUser = () => ({type: userConsts.EXIT_USER})
export const vaccinationOrTests = (values) => ({type: userConsts.VACCINA_TEST, values})
export const getScan = (values) => ({type: userConsts.SAGA_GET_SCAN, values})
export const setScan = (payload) => ({type: userConsts.SET_SCAN, payload})
export const sagaTests = (values) => ({type: userConsts.SAGA_TESTS, values})
export const setTests = (data) => ({type: userConsts.SET_ADMIN_TESTS, data})
export const sagaVacc = (values) => ({type: userConsts.SAGA_VACC, values})
export const setVacc = (data) => ({type: userConsts.SET_ADMIN_VACC, data})
export const resetAdminTest = () => ({type: userConsts.RESET_ADMIN_TESTS})
export const resetAdminVacc = () => ({type: userConsts.RESET_ADMIN_VACC})
export const sagaDel = (values) => ({type: userConsts.SAGA_DEL, values})
export const setDel = (data) => ({type: userConsts.SET_DEL, data})
export const setIsDataSending = (isDataSending) => ({type: userConsts.IS_DATA_SENDING, isDataSending})
export const setIsPDF = (value) => ({type: userConsts.IS_PDF, value})
export const sagalistVaccWotcher = () => ({type: userConsts.LIST_SAGA_VACC})
// export const getListOfVaccinations = (list) => ({type: userConsts.DOWNLOAD_OF_VACCINATIONS, list})
export const sagalistPCRWotcher = () => ({type: userConsts.LIST_SAGA_PCRTEST})
// export const getListOfPCRTest = (list) => ({type: userConsts.DOWNLOAD_OF_PCRTEST, list})






import React, { useState, useEffect } from "react";
import checkedPNG from "../../assets/checked1.png";
import { ScanModal } from "../../pages/ScanModal/ScanModal";
// import DownloadLink from "react-download-link"
// import fileDownload from 'js-file-download' //!
// import { saveAs } from 'file-saver';
import Loader2 from "../../components/Loader2/Loader2";
import "./VaccComponent.scss";

export const VaccComponent = ({
  pages,
  handlerVacc,
  AdminVacc,
  dispatch,
  useSelector,
  sagalistVaccWotcher,
  setIsDataSending,
}) => {
  useEffect(() => {
    return dispatch(setIsDataSending(false));
  }, []);

  const [isModalScan, setIsModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [idScan, setIdScan] = useState("");
  const [login, setLogin] = useState(null);

  const isDataSending = useSelector((state) => state.userReducer.isDataSending);

  const showModalScan = (imgValue, id, login) => {
    setIsModal(true);
    setImgSrc(imgValue);
    setIdScan(id);
    setLogin(login);
  };

  const getListVacc = () => {
    console.log("Lets start to download");
    dispatch(setIsDataSending(true));
    dispatch(sagalistVaccWotcher());
  };

  return (
    <>
      {isDataSending && (
        <div className="loader-block">
          <Loader2 />
        </div>
      )}
      {isModalScan && (
        <ScanModal
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
          login={login}
        />
      )}
      {AdminVacc !== null && (
        <>
          <button onClick={getListVacc} className="btn btn-import-excel">
            Экспорт в Excel
          </button>
          {/* {
            listOfVaccinations &&
            <a href={`${listOfVaccinations}`} type="application / vnd.ms - excel" target="_blank" download="vacc.xlsx"> Download Here </a>
          } */}
          {/* <DownloadLink
            label='Download'
            filename='Vacc.xlsx'
            exportFile={() => listOfVaccinations}
          /> */}
          <table className="vacc-table">
            <thead>
              <tr>
                <th>Дата время записи</th>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>Отчество</th>
                <th>Дата рождения</th>
                <th>Дата 1й вакцинации</th>
                <th>Дата 2й вакцинации</th>
                <th>Скан сертификата прививки</th>
              </tr>
            </thead>
            <tbody>
              {AdminVacc[0].Vaccination.map((vacc) => {
                return (
                  <tr key={vacc.id}>
                    <td>{vacc.Created}</td>
                    <td>{vacc.LName}</td>
                    <td>{vacc.FName}</td>
                    <td>{vacc.MName}</td>
                    <td>{vacc.DateOfBirthDate}</td>
                    <td>{vacc.DateOfFirstVaccination}</td>
                    <td>{vacc.DateOfSecondVaccination}</td>
                    <td className={vacc.HaveScan ? "attached" : ""}>
                      {vacc.HaveScan ? (
                        <img
                          src={checkedPNG}
                          alt=""
                          onClick={() =>
                            showModalScan("getCertV", vacc.id, vacc.Login)
                          }
                        />
                      ) : (
                        "Скан отсутсвует"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="paginations">
            {pages.length > 1 &&
              pages.map((page) => (
                <span
                  key={page}
                  className={
                    page === AdminVacc[0].PageInfo.PageNumber
                      ? "active-page"
                      : ""
                  }
                  onClick={() => handlerVacc(page)}
                >
                  {page}
                </span>
              ))}
          </div>
        </>
      )}
    </>
  );
};

import { put, takeEvery, call } from 'redux-saga/effects'
import { gradientAPI } from '../../api/api'
import { setAuthAnswer, setScan, setIsPDF } from '../AC'
import { userConsts } from '../../static/consts'

function* getScanWorker (values) {
  console.log(values)
  try {
    const response = yield call(gradientAPI.getScan, values)
    console.log(response.data)
    if (response.data.Content.match('data:application/pdf')) {
      yield put (setIsPDF(true))
    } else {
      yield put (setIsPDF(false))
    }
    if (response.data.IsError) {
      console.log('IsError: ', response.data.IsError)
      yield put (setAuthAnswer(response.data.IsError)) 
    } else {
      yield put (setScan(response.data))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* getScanWotcher () {
  yield takeEvery(userConsts.SAGA_GET_SCAN, getScanWorker) 
}
export const userConsts = {
  SET_AUTH_ANSWER: 'SET_AUTH_ANSWER',
  SET_USER_DATA: 'SET_USER_DATA',
  SAGA_AUTH: 'SAGA_AUTH',
  SAGA_UPDATE: 'SAGA_UPDATE',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  EXIT_USER: 'EXIT_USER',
  VACCINA_TEST: 'VACCINA_TEST',
  SAGA_GET_SCAN: 'SAGA_GET_SCAN',
  SET_SCAN: 'SET_SCAN',
  SAGA_TESTS: 'SAGA_TESTS',
  SET_ADMIN_TESTS: 'SET_ADMIN_TESTS',
  SAGA_VACC: 'SAGA_VACC',
  SET_ADMIN_VACC: 'SET_ADMIN_VACC',
  RESET_ADMIN_TESTS: 'RESET_ADMIN_TESTS',
  RESET_ADMIN_VACC: 'RESET_ADMIN_VACC',
  SAGA_DEL: 'SAGA_DEL',
  SET_DEL: 'SET_DEL',
  IS_DATA_SENDING: 'IS_DATA_SENDING',
  IS_PDF: 'IS_PDF',
  LIST_SAGA_VACC: 'LIST_SAGA_VACC',
  LIST_SAGA_PCRTEST: 'LIST_SAGA_PCRTEST',
  DOWNLOAD_OF_VACCINATIONS: 'DOWNLOAD_OF_VACCINATIONS',
  DOWNLOAD_OF_PCRTEST: 'DOWNLOAD_OF_PCRTEST'
}
import React, { useState } from 'react'
import './TRModalVacc.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga } from '../../redux/AC'
import Calendar from 'react-calendar'
import { handleDate, getBase64 } from '../../static/functions'
import savePNG from '../../assets/icons8-save-as-50.png'
import calendarPNG from  '../../assets/today_black_24dp.svg'
import checkedPNG from '../../assets/checked1.png'
import visiblePNG from '../../assets/visibility1x.png'
import { ScanModal } from '../ScanModal/ScanModal'


export const TRModalVacc = ({dataEdit, setIsEdidTR}) => {

  const dispatch = useDispatch()

  const Login = useSelector((state) => state.userReducer.Login)
  
  // const [showcalendar, setShowcalendar] = useState(false)
  const [showcalendar1, setShowcalendar1] = useState(false)
  const [showcalendar2, setShowcalendar2] = useState(false)
  const [selectedVaccineDay1, setSelectedTmpVaccineDay1] = useState('')
  const [selectedVaccineDay2, setSelectedTmpVaccineDay2] = useState('')
  const [fileSert, setFileSert] = useState(null);

  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')

  const currentSelectedData = (e, setSelectedDay, setCalendar) => {
    console.log(e)
    setSelectedDay(handleDate(e))
    setCalendar(false)
  }

  const showModalScan = (imgValue, id) => {
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }

  const updateDataUser = (id, numberPage = 1) => {
    console.log(numberPage, id, fileSert)
    const values = {
      Login,
      Tests: {
        Test: [],
      },
      Vaccinations: {
        Vaccination: [
          {
            id,
            DateOfFirstVaccination: selectedVaccineDay1,
            DateOfSecondVaccination: selectedVaccineDay2,
            ScanOfVaccinationCertificate: fileSert,
          }
        ]
      }
    }
    dispatch(updateSaga(values))
    setIsEdidTR(false)
  }

  return (
    <div className="tr-modal">
      {
        isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      <div className="tr-modal-content">
        <span className="close" onClick={() => setIsEdidTR(false)}>&times;</span>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="react-calendar">
                  <img src={calendarPNG} alt="" onClick={() => setShowcalendar1(!showcalendar1)} />
                  {
                    selectedVaccineDay1 ?
                      <div className="show-date">
                        {selectedVaccineDay1}
                      </div> 
                      :
                      <div className="show-date">
                        {dataEdit.DateOfFirstVaccination}
                      </div>
                  }
                  { showcalendar1 &&
                    <Calendar
                      onClickDay={(e) => currentSelectedData(e, setSelectedTmpVaccineDay1, setShowcalendar1)}
                    />
                  }
                </div>
              </td>
              <td>
                <div className="react-calendar">
                  <img src={calendarPNG} alt="" onClick={() => setShowcalendar2(!showcalendar2)} />
                  {
                    selectedVaccineDay2 ?
                      <div className="show-date">
                        {selectedVaccineDay2}
                      </div> 
                      :
                      <div className="show-date">
                        {dataEdit.DateOfSecondVaccination}
                      </div>
                  }
                  { showcalendar2 &&
                    <Calendar
                      onClickDay={(e) => currentSelectedData(e, setSelectedTmpVaccineDay2, setShowcalendar2)}
                    />
                  }
                </div>
              </td>
              <td className='attached'>
                <label className="custom-file-upload">
                  <input type="file" onChange={(e) => getBase64(e, setFileSert)} />
                  Прикрепить сертификат
                </label>
                <div>
                  {
                    dataEdit.HaveScan && !fileSert ?
                    <img src={visiblePNG} alt="" onClick={() => showModalScan('getCertV', dataEdit.id)}/>
                    :
                    <img src={visiblePNG} alt="" onClick={() => showModalScan(fileSert, dataEdit.id)}/>
                  }
                </div>
                
              </td>
              <td className="save-td">
                <img src={savePNG} alt="" onClick={() => updateDataUser(dataEdit.id)}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
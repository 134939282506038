import { put, takeEvery, call } from 'redux-saga/effects'
import { gradientAPI } from '../../api/api'
import { setTests } from '../AC'
import { userConsts } from '../../static/consts'

function* testsWorker (values) {
  console.log(values)
  try {
    const response = yield call(gradientAPI.getTests, values)
    console.log(response.data)
    const Tests = {
      Test: response.data.Tests,
      PageInfo: response.data.PageInfo
    }
    yield put (setTests([Tests]))
    
  } catch (err) {
    console.log(err)
  }
}

export function* testsWotcher () {
  yield takeEvery(userConsts.SAGA_TESTS, testsWorker) 
}
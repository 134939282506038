import React from 'react'
import './Footer.scss'
import logoGradient from '../../assets/gradient.png'
import emailPNG from '../../assets/email1x.png'
import callPNG from '../../assets/call1x.png'

export const Footer = () => {
  return (
    <div className="footer">
      <div className="logo">
        <img src={logoGradient} alt="" />
      </div>
      <div className="footer-title">
        Градиент против Covida
      </div>
      <div className="contacts">
        <div className="contacts-email">
          <img src={emailPNG} alt="" />
          <span><a href="mailto:a.chechura@gradient.ru">a.chechura@gradient.ru</a></span>
        </div>
        <div className="contacts-phone">
          <img src={callPNG} alt="" />
          <span>+7 495 933 6000 +1160</span>
        </div>
        
      </div>
    </div>
  )
}
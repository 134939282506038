import { all } from 'redux-saga/effects'
import { authUserWotcher } from './sagaAuth'
import { updateWotcher } from './sagaUpdate'
import { getScanWotcher } from './sagaGetScanContent'
import { testsWotcher } from './sagaGetTests'
import { vaccWotcher } from './sagaGetVacc'
import { deleteWotcher } from './sagaDel'
import { listVaccWotcher } from './sagaLoadListVacc'
import { listPCRWotcher } from './sagaLoadListPCRTest'

export function* rootWatcher () {
  yield all ([authUserWotcher(), updateWotcher(), getScanWotcher(), testsWotcher(), vaccWotcher(), deleteWotcher(), listVaccWotcher(), listPCRWotcher()])
}

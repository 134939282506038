import { put, takeEvery, call } from 'redux-saga/effects'
import { gradientAPI } from '../../api/api'
import { setVacc } from '../AC'
import { userConsts } from '../../static/consts'

function* vaccWorker (values) {
  console.log(values)
  try {
    const response = yield call(gradientAPI.getVacc, values)
    console.log(response.data)
    const Vaccinations = {
      Vaccination: response.data.Vaccinations,
      PageInfo: response.data.PageInfo
    }
    yield put (setVacc([Vaccinations]))
    
  } catch (err) {
    console.log(err)
  }
}

export function* vaccWotcher () {
  yield takeEvery(userConsts.SAGA_VACC, vaccWorker) 
}
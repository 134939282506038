import './App.scss';
import { Route } from 'react-router-dom'
import { LoginForm } from './components/forms/LoginForms'
import { UserPage } from './pages/UserPage/UserPage'
import { AdminPage } from './pages/AdminPage/AdminPage'

function App() {
  return (
    <div className="container">
      <Route exact path='/' render={() => <LoginForm />} />
      <Route exact path='/user' render={() => <UserPage />} /> 
      <Route exact path='/adminpage' render={() => <AdminPage />} />
    </div>
  );
}

export default App;

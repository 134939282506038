import React from 'react'
import './ScanModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getScan, setScan, setIsPDF } from '../../redux/AC'
import { convertDataURIToBinary } from '../../static/functions'
import Loader from '../../components/Loader/Loader'
// import { Base64 } from 'js-base64';
// import { Document, Page } from 'react-pdf';


export const ScanModal = ({setIsModal, imgSrc, idScan, login}) => {

  console.log('ScanModal', imgSrc)
  const dispatch = useDispatch()
  
  const Login = useSelector((state) => state.userReducer.Login)
  const Content = useSelector((state) => state.userReducer.Content)
  const isPDF = useSelector((state) => state.userReducer.isPDF)
 
  const uintArray = convertDataURIToBinary(String(Content))
  let blob = ''
  if (isPDF) {
    blob = new Blob([uintArray], {type: 'application/pdf'})
  } else {
    blob = new Blob([uintArray], {type: 'image/jpeg,image/png'})
  }
  const blobURL = URL.createObjectURL(blob)

  let blobURLSrc = ''
  if (imgSrc && imgSrc !== 'getCertV' && imgSrc !== 'getPcr' ) {
    console.log('!!!!!')
    const uintArrayImg = convertDataURIToBinary(String(imgSrc))
    if (imgSrc.match('data:application/pdf')) {
      dispatch(setIsPDF(true))
      const blobImg = new Blob([uintArrayImg], {type: 'application/pdf'});
      blobURLSrc = URL.createObjectURL(blobImg)
    } else {
      dispatch(setIsPDF(false))
      const blobImg = new Blob([uintArrayImg], {type: 'image/jpeg,image/png'});
      blobURLSrc = URL.createObjectURL(blobImg)
    }
  } else {
    const values = {
        Login : login ? login : Login,
        id: idScan,
        TypeScan : imgSrc === 'getCertV' ? "ScanOfVaccinationCertificate" : "ScanTest"
      }
      dispatch(getScan(values))
  }
  const handlerClose = () => {
    dispatch(setScan(''))
    setIsModal(false)
  }

  return(
    <>
      {
        Content  || blobURLSrc ?
        <div className="scanmodal">
          <span className="close" onClick={handlerClose}>&times;</span>
          <div className="scanmodal-content">
            {
              Content && imgSrc === 'getCertV' || Content && imgSrc === 'getPcr' ?
                isPDF ? <object type="application/pdf" data={blobURL} width="800px" height="1100px" /> 
                : <img src={blobURL} alt='' /> 
              :
                isPDF ? <object type="application/pdf" data={blobURLSrc} width="800px" height="1100px" /> 
                : <img src={blobURLSrc} alt='' />
            }
          </div>
        </div>
        :
        <div className="loader-container"><Loader /></div>
      }
    </>
  )
}
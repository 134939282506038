import React, { useEffect } from 'react'
import './AdminPage.scss'
import { useDispatch, useSelector } from 'react-redux'
import { 
  sagaTests, 
  sagaVacc, 
  resetAdminTest, 
  resetAdminVacc, 
  sagalistVaccWotcher, 
  setIsDataSending, 
  sagalistPCRWotcher } from '../../redux/AC'
import { AdminHeader } from '../AdminHeader/AdminHeader'
import { TestsComponent } from '../../components/TestsComponent/TestsComponent'
import { VaccComponent } from '../../components/VaccComponents/VaccComponents'
import Loader from '../../components/Loader/Loader'

export const AdminPage = () => {
  
  const dispatch = useDispatch()
  const AdminTest = useSelector((state) => state.userReducer.AdminTest)
  const AdminVacc = useSelector((state) => state.userReducer.AdminVacc)

  useEffect(() => {
    dispatch(sagaTests(1))
  }, [])

  const pages = []
  if (AdminTest !== null) {
    for (let i = 1; i <= AdminTest[0].PageInfo.TotalPages; i++) {
      pages.push(i)
    }
  }
  if (AdminVacc !== null) {
    console.log(AdminVacc[0].PageInfo.TotalPages)
    for (let i = 1; i <= AdminVacc[0].PageInfo.TotalPages; i++) {
      pages.push(i)
    }
  }

  const handlerTests = (number) => {
    dispatch(resetAdminVacc())
    dispatch(sagaTests(number))
  }

  const handlerVacc = (pageValue) => {
    console.log('handlerVacc')
    dispatch(resetAdminTest())
    dispatch(sagaVacc(pageValue))
  }

  return (
    <div className="admin-page-tests">
      <AdminHeader
        AdminTest={AdminTest}
        handlerTests={handlerTests}
        AdminVacc={AdminVacc}
        handlerVacc={handlerVacc}
      />
      { AdminTest === null && AdminVacc === null ? <div className="loader-container"><Loader /></div>  :
        <TestsComponent
          pages={pages}
          handlerTests={handlerTests}
          AdminTest={AdminTest}
          dispatch={dispatch}
          useSelector={useSelector}
          sagalistPCRWotcher={sagalistPCRWotcher}
          setIsDataSending={setIsDataSending}
        />
      }
      <VaccComponent 
        pages={pages}
        handlerVacc={handlerVacc}
        AdminVacc={AdminVacc}
        dispatch={dispatch}
        useSelector={useSelector}
        sagalistVaccWotcher={sagalistVaccWotcher}
        setIsDataSending={setIsDataSending}
      />
    </div>
  )
}
import { userConsts } from '../static/consts'

let initialState = {
  IsError: null,
  id: null, // ID пользователя в БД
  Login: '', //логин пользователя
  UserRole: '', // роль пользователя, по умолчанию User. Может быть следящего значения User,Moderator,Admin и SuperAdmin
  LName: '', //Фамилия
  FName: '', //Имя
  MName: null, //Отчество
  DateOfBirthDate: null, //Дата рождения
  Vaccinations: { //Инлформация о вакцинации, включая информацию для постраничного вывода
    Vaccination: [], //Список с вакцинацией, если нет то будем пустым
    PageInfo: {}
  },
  Tests: { //Список тестов с постраничной информацией
    Test: [], //Список тестов, если нет то будем пустым
    PageInfo: {}
  },
  showVaccination: true,
  showTests: false,
  Content: null,
  AdminTest: null,
  AdminVacc: null,
  isDataSending: false,
  isPDF: false,
  listOfVaccinations: null,
  listOfPCRTests: null
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConsts.SET_AUTH_ANSWER:
      return {
        ...state,
        IsError: action.payload
      }
    case userConsts.SET_USER_DATA:
      return {
        ...state,
        IsError: action.payload.IsError,
        id: action.payload.UserInfo.id,
        Login: action.payload.UserInfo.Login,
        UserRole: action.payload.UserInfo.UserRole,
        LName: action.payload.UserInfo.LName,
        FName: action.payload.UserInfo.FName,
        MName: action.payload.UserInfo.MName,
        DateOfBirthDate: action.payload.UserInfo.DateOfBirthDate,
        Vaccinations: { 
          Vaccination: [...action.payload.UserInfo.Vaccinations.Vaccination],
          PageInfo: {...action.payload.UserInfo.Vaccinations.PageInfo}
        },
        Tests: { 
          Test: [...action.payload.UserInfo.Tests.Test],
          PageInfo: {...action.payload.UserInfo.Tests.PageInfo},
        }    
      }
        case userConsts.VACCINA_TEST:
          return {
            ...state,
            showVaccination: action.values.vaccina,
            showTests: action.values.test
          }
        case userConsts.EXIT_USER:
          return {
            ...state,
            IsError: null,
            id: null,
            Login: '',
            UserRole: '',
            LName: '',
            FName: '',
            MName: null,
            DateOfBirthDate: null,
            DateOfFirstVaccination: null,
            DateOfSecondVaccination: null,
            ScanOfVaccinationCertificate:null,
            DateOPCRTest: null,
            ScanOfPRCTest: null
          }
        case userConsts.SET_SCAN:
          return {
            ...state,
            Content: action.payload.Content 
          }
        case userConsts.SET_ADMIN_TESTS:
          return {
            ...state,
            AdminTest: action.data,
            AdminVacc: null
          }
        case userConsts.SET_ADMIN_VACC:
          return {
            ...state,
            AdminVacc: action.data,
            AdminTest: null
          }
        case userConsts.RESET_ADMIN_TESTS:
          return {
            ...state,
            AdminTest: null
          }
        case userConsts.RESET_ADMIN_VACC:
          return {
            ...state,
            AdminVacc: null
          }
        case userConsts.IS_DATA_SENDING:
          return {
            ...state,
            isDataSending: action.isDataSending
          }
        case userConsts.IS_PDF:
          return {
            ...state,
            isPDF: action.value
          }
        case userConsts.DOWNLOAD_OF_VACCINATIONS:
          return {
            ...state,
            listOfVaccinations: action.list
          }
        case userConsts.DOWNLOAD_OF_PCRTEST:
          return {
            ...state,
            listOfPCRTests: action.list
          }
    default: return state
  }
}

export default userReducer
import React, { useState, useEffect } from "react";
import checkedPNG from "../../assets/checked1.png";
import { ScanModal } from "../../pages/ScanModal/ScanModal";
import Loader2 from "../../components/Loader2/Loader2";
import "./TestsComponent.scss";

export const TestsComponent = ({
  pages,
  handlerTests,
  AdminTest,
  dispatch,
  useSelector,
  sagalistPCRWotcher,
  setIsDataSending,
}) => {
  useEffect(() => {
    return () => dispatch(setIsDataSending(false));
  }, []);

  const [isModalScan, setIsModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [idScan, setIdScan] = useState("");
  const [login, setLogin] = useState(null);

  const isDataSending = useSelector((state) => state.userReducer.isDataSending);

  const showModalScan = (imgValue, id, login) => {
    setIsModal(true);
    setImgSrc(imgValue);
    setIdScan(id);
    setLogin(login);
  };
  const getListPCRTest = () => {
    dispatch(setIsDataSending(true));
    dispatch(sagalistPCRWotcher());
  };

  return (
    <>
      {isDataSending && (
        <div className="loader-block">
          <Loader2 />
        </div>
      )}
      {isModalScan && (
        <ScanModal
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
          login={login}
        />
      )}
      {AdminTest !== null && (
        // console.log(AdminTest[0].Test)
        <>
          <button onClick={getListPCRTest} className="btn btn-import-excel">
            Экспорт в Excel
          </button>
          <table className="tests-table">
            <thead>
              <tr>
                <th>Дата время записи</th>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>Отчество</th>
                <th>Дата рождения</th>
                <th>Дата теста на ПЦР</th>
                <th>Скан теста на ПЦР</th>
              </tr>
            </thead>
            <tbody>
              {AdminTest[0].Test.map((test) => {
                return (
                  <tr key={test.id}>
                    <td>{test.Created}</td>
                    <td>{test.LName}</td>
                    <td>{test.FName}</td>
                    <td>{test.MName}</td>
                    <td>{test.DateOfBirthDate}</td>
                    <td>{test.DateTest}</td>
                    <td className={test.HaveScan ? "attached" : ""}>
                      {test.HaveScan ? (
                        <img
                          src={checkedPNG}
                          alt=""
                          onClick={() =>
                            showModalScan("getPcr", test.id, test.Login)
                          }
                        />
                      ) : (
                        "Скан отсутсвует"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="paginations">
            {pages.length > 1 &&
              pages.map((page) => (
                <span
                  key={page}
                  className={
                    page === AdminTest[0].PageInfo.PageNumber
                      ? "active-page"
                      : ""
                  }
                  onClick={() => handlerTests(page)}
                >
                  {page}
                </span>
              ))}
          </div>
        </>
      )}
    </>
  );
};

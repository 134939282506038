import { combineReducers, createStore, applyMiddleware } from 'redux'
import userReducer from './userReducer'
import createSagaMiddleware from 'redux-saga'
import { rootWatcher } from './saga/index'

const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
  userReducer
})

const storage = createStore(rootReducer, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootWatcher)

export default storage
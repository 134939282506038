import { put, takeEvery, call } from 'redux-saga/effects'
import { gradientAPI } from '../../api/api'
import { setAuthAnswer, setUserData, setIsDataSending } from '../AC'
import { userConsts } from '../../static/consts'

function* updateWorker (values) {
  console.log(values)
  try {
    const response = yield call(gradientAPI.getUpdate, values)
    console.log(response.data)
    if (response.data.IsError) {
      console.log('IsError: ', response.data.IsError)
      yield put (setAuthAnswer(response.data.IsError)) 
    } else {
      yield put (setUserData(response.data))
      yield put (setIsDataSending(response.data.IsError))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* updateWotcher () {
  yield takeEvery(userConsts.SAGA_UPDATE, updateWorker) 
}
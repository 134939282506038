import React, { useState } from 'react'
import './TRModalTest.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga } from '../../redux/AC'
import Calendar from 'react-calendar'
import { handleDate, getBase64 } from '../../static/functions'
import savePNG from '../../assets/icons8-save-as-50.png'
import calendarPNG from  '../../assets/today_black_24dp.svg'
import checkedPNG from '../../assets/checked1.png'
import visiblePNG from '../../assets/visibility1x.png'
import { ScanModal } from '../ScanModal/ScanModal'


export const TRModalTest = ({dataEdit, setIsEdidTR}) => {

  const dispatch = useDispatch()

  const Login = useSelector((state) => state.userReducer.Login)
  
  const [showcalendar, setShowcalendar] = useState(false)
  const [selectedPcrTestDay, setSelectedPcrTestDay] = useState('')
  const [filePcr, setFileSPcr] = useState(null);

  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')

  const currentSelectedData = (e) => {
    console.log(e)
    setSelectedPcrTestDay(handleDate(e))
    setShowcalendar(false)
  }

  const showModalScan = (imgValue, id) => {
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }

  const updateDataUser = (id, numberPage = 1) => {
    console.log(id, numberPage, filePcr)
    const values = {
      Login,
      Tests: {
        Test: [
          {
            id,
            DateTest: selectedPcrTestDay,
            ScanTest: filePcr,
          }
        ],
        PageInfo: {
          PageNumber: numberPage,
        }
      },
      Vaccinations: {
        Vaccination: []
      }
    }
    dispatch(updateSaga(values))
    setIsEdidTR(false)
  }

  return (
    <div className="tr-modal">
      {
        isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      <div className="tr-modal-content">
        <span className="close" onClick={() => setIsEdidTR(false)}>&times;</span>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="react-calendar">
                  <img src={calendarPNG} alt="" onClick={() => setShowcalendar(!showcalendar)} />
                  {
                    selectedPcrTestDay ?
                      <div className="show-date">
                        {selectedPcrTestDay}
                      </div> 
                      :
                      <div className="show-date">
                        {dataEdit.DateTest}
                      </div>
                  }
                  { showcalendar &&
                    <Calendar
                      onClickDay={(e) => currentSelectedData(e)}
                    />
                  }
                </div>
              </td>
              <td className='attached'>
                <label className="custom-file-upload">
                  <input type="file" onChange={(e) => getBase64(e, setFileSPcr)} />
                  Прикрепить ПЦР-скан
                </label>
                <div>
                  {
                    dataEdit.HaveScan && !filePcr ?
                    <img src={visiblePNG} alt="" onClick={() => showModalScan('getPcr', dataEdit.id)}/>
                    :
                    <img src={visiblePNG} alt="" onClick={() => showModalScan(filePcr, dataEdit.id)}/>
                  }
                </div>
                
              </td>
              <td className="save-td">
                <img src={savePNG} alt="" onClick={() => updateDataUser(dataEdit.id)}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { exitUser } from '../../redux/AC'
import './AdminHeader.scss'
import { vaccinationOrTests } from '../../redux/AC'

export const AdminHeader = ({handlerTests, AdminTest, AdminVacc, handlerVacc}) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const showVaccination = useSelector((state) => state.userReducer.showVaccination)
  const showTests = useSelector((state) => state.userReducer.showTests)

  const [showMenu, setShowMenu] = useState(['header__menu'])
  const [clsBurger, setClsBurger] = useState(['header__burger'])

  const handlerExitUser = () => {
    dispatch(exitUser())
    history.push('/')
    delete localStorage['dataUser'] 
  } 

  const handlerVisibleMenu = (vaccina, test) => {
    dispatch(vaccinationOrTests({
      vaccina,
      test
    }))
    if (showMenu.indexOf('active') === -1) {
      setShowMenu([...showMenu, 'active'])
      setClsBurger([...clsBurger, 'active'])
    } else {
      setShowMenu([...showMenu.splice(showMenu.indexOf('header__menu'), 1)])
      setClsBurger([...clsBurger.splice(clsBurger.indexOf('header__burger'), 1)])
    }
  }
    
  return (
    <div className="headeradmin">
      <div className="header__body">
        <div className="header__logo">Информация о вакцинировании</div>
        <div 
          className={clsBurger.join(" ")} 
          onClick={handlerVisibleMenu}  
        >
          <span></span>
        </div>
        <div className={showMenu.join(" ")}>
          <ul className="header__list">
            <li className="header__list-item">
              <div 
                className={AdminTest ? 'header__list-link active' : 'header__list-link'}
                // onClick={() => handlerVisibleMenu(true, false)} 
                onClick={() => handlerTests(1)} 
              >
                Информация по тестам
              </div>
            </li>
            <li className="header__list-item">
              <div 
                className={AdminVacc ? 'header__list-link active' : 'header__list-link'}
                // onClick={() => handlerVisibleMenu(false, true)}
                onClick={() => handlerVacc(1)}  
              >
                Информация по вакцинации
              </div>
            </li>
            <li className="header__list-item">
              <div 
                className="header__list-link"
                onClick={() => {history.push('/user')}}  
              >
                Личный кабинет
              </div>
            </li>
            <li className="header__list-item">
              <div 
                className="header__list-link"
                onClick={handlerExitUser}  
              >
                Выйти
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

import { Base64 } from 'js-base64';

export const handleDate = (value) => {
  if (value) {
    const day = value.getDate() < 10 ? '0' + value.getDate() : value.getDate()
    const month = (value.getMonth() + 1) < 10 ? '0' + (value.getMonth() + 1) : (value.getMonth() + 1)
    return `${day}.${month}.${value.getFullYear()}`
  } else {
    return
  }
}
////Base64//////////////////////////////////////////////////////////////////
export function getBase64(e, fnName) {
  var reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onload = function () {
    console.log(reader.result)
    console.log(fnName)
    // if(reader.result.match('data:application/pdf')) {
    //   console.log('This PDF!!!!!!')
    // } else {
    //   alert('Необходимо использовать только PDF-файлы!')
    //   return
    // }
    fnName(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export function convertDataURIToBinary(dataURI) {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
////Base64///////////////////////////////////////////////////////////////////////
let BASE64_MARKER = ';base64,';

export function saveInLocalStorage (data) {
  localStorage.setItem('dataUser', JSON.stringify(data))
}

export const currentSelectedData = (event, setDay, closeCalendar) => {
  console.log(event, setDay, closeCalendar)
  setDay(event)
  closeCalendar(false)
}

// export const updateDataUser = (selectedVaccineDay1, selectedVaccineDay2, selectedPcrTestDay, fileSert) => {
//   let 
//     dateVaccine1,
//     dateVaccine2,
//     datePcrTestDay

//   if (selectedVaccineDay1 !== '') dateVaccine1 = handleDate(selectedVaccineDay1)
//   if (selectedVaccineDay2 !== '') dateVaccine2 = handleDate(selectedVaccineDay2)
//   if (selectedPcrTestDay !== '' && selectedPcrTestDay !== 'empty') {
//     datePcrTestDay = handleDate(selectedPcrTestDay)
//   } else {
//     datePcrTestDay = selectedPcrTestDay
//   }

//   const values = {
//     Login,
//     dateVaccine1,
//     dateVaccine2,
//     fileSert,
//     datePcrTestDay,
//     filePcr
//   }
//   dispatch(updateSaga(values))
// }

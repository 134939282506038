import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import Loader from '../Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import * as Yup from 'yup'
import { getAuthSaga } from '../../redux/AC'
import './LoginForm.scss'
//components:
import { InputForm } from '../InputElement/InputForm'

export const LoginForm = () => {

  const [isLoader, setIsLoader] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const IsError = useSelector((state) => state.userReducer.IsError)
  const id = useSelector((state) => state.userReducer.id)

  useEffect(() => {
    if (id !== null && IsError === false) history.push('/user')
  },[IsError])


  return(
    <div className="form">
      { isLoader &&
        <Loader />
      }
      <div className="form__header">
        <div className="form__title">
          <h2>Градиент против Covida</h2>
          <h3>Личный кабинет</h3> 
        </div>
      </div>
      <div className="form__body">
        <Formik
          initialValues={{
            login: '',
            password: '',
          }}
          validationSchema={Yup.object({
            login: Yup.string().required('Поле не должно быть пустым'),
            password: Yup.string().required('Поле не должно быть пустым')
          })}
          onSubmit = {(dataForm) => {
            setIsLoader(true)
            dispatch(getAuthSaga(dataForm))
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <InputForm
                label="Login"
                id="login"
                type="text"
                name="login"
                placeholder="Login..."
                required={true}
              />
              <InputForm
                label="Password"
                id="password"
                type="password"
                name="password"
                placeholder="Password..."
                required={true}
              />
              <div className="actions">
                {  isValid && dirty ?  <button className="btn">Войти в систему</button> :
                  <button className="btn-disabled" disabled>Войти в систему</button> 
                }
                
              </div>
              {
                IsError === true &&
                <div className="err-auth">
                  Не корректный логин или пароль
                </div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
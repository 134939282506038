import React, { useState } from 'react'
import './Vaccination.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga, sagaDel, setIsDataSending, setScan} from '../../redux/AC'
import { Vaccination2 } from '../Vaccination2/Vaccination2'
import checkedPNG from '../../assets/checked1.png'
import plusPNG from '../../assets/plus2x.png'
import pencilPNG from '../../assets/pencilBlack1x.png'
import deletePNG from '../../assets/delete1x.png'
import { handleDate, getBase64, currentSelectedData } from '../../static/functions'
import { ScanModal } from '../../pages/ScanModal/ScanModal'
import { TRModalVacc } from '../../pages/TRModalVacc/TRModalVacc'
import Loader from '../../components/Loader/Loader'

export const Vaccination = () => {

  const [isEditTR, setIsEdidTR] = useState(false)
  const [dataEdit, setDataEdit] = useState(null)

  const dispatch = useDispatch()
  const [showVaccination2, setShowVaccination2] = useState(false)

  const Login = useSelector((state) => state.userReducer.Login) 
  const Vaccinations = useSelector((state) => state.userReducer.Vaccinations)
  const isDataSending = useSelector((state) => state.userReducer.isDataSending)
  const Content = useSelector((state) => state.userReducer.Content)

  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')

  const pages = []
  if (Vaccinations !== null) {
    for (let i = 1; i <= Vaccinations.PageInfo.TotalPages; i++) {
      pages.push(i)
    }
  }

  const showModalScan = (imgValue, id) => {
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }

  const updateDataUser = (numberPage = 1) => {
    dispatch(setIsDataSending(true))
    const values = {
      Login,
      Vaccinations: {
        Vaccination: [],
        PageInfo: {
          PageNumber: numberPage,
        }
      },
      Tests: {
        Test: []
      }
    }
    dispatch(updateSaga(values))
  }

  const editTRData = (vacc) => {
    setIsEdidTR(true)
    setDataEdit(vacc)
  }

  const deleteValue = (id) => {
    dispatch(sagaDel({
      id,
      Login,
      TypeScan: "ScanOfVaccinationCertificate" 
    }))
    updateDataUser()
  }

  return (
    <>
      { 
        isDataSending &&
        <div className="loader-container">
          <Loader />
        </div>
      }
      { isEditTR &&
        <TRModalVacc
          dataEdit={dataEdit}
          setIsEdidTR={setIsEdidTR}
        />
      }
      {
        isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      <h2 className="title">ВАКЦИНАЦИЯ</h2>
      <table className="table-vcc">
        <thead>
          <tr>
            <th>Дата время записи</th>
            <th>1ая вакцинация</th>
            <th>2ая вакцинация</th>
            <th>Скан сертификата прививки</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            Vaccinations.Vaccination.length > 0 ?
            Vaccinations.Vaccination.map(vacc => {
              return(
                <tr key={vacc.id}>
                  <td>{vacc.Created}</td>
                  <td>{vacc.DateOfFirstVaccination}</td>
                  <td>{vacc.DateOfSecondVaccination}</td>
                  <td className={vacc.HaveScan ? 'attached' : ''}>
                      { vacc.HaveScan ?
                        <img 
                          src={checkedPNG} alt="" 
                          onClick={() => showModalScan('getCertV', vacc.id)}
                        />
                        : 'Скан отсутсвует'
                      } 
                  </td>
                  <td className="edit-td">
                    <img src={pencilPNG} alt="" onClick={() => editTRData(vacc)}/>
                  </td>
                  <td className="delete-td">
                    <img src={deletePNG} alt="" onClick={() => deleteValue(vacc.id)}/>
                  </td>
                </tr>
              )
          }) :  /////////////////////////////////////////////////////////////////////////////////////
          <Vaccination2
            handleDate={handleDate}
            currentSelectedData={currentSelectedData}
            getBase64={getBase64}
            Login={Login}
            dispatch={dispatch}
            updateSaga={updateSaga}
            setShowVaccination2={setShowVaccination2}
            Vaccinations={Vaccinations}
          /> 
        }
        {
          showVaccination2 &&
          <Vaccination2
            handleDate={handleDate}
            currentSelectedData={currentSelectedData}
            getBase64={getBase64}
            Login={Login}
            dispatch={dispatch}
            updateSaga={updateSaga}
            setShowVaccination2={setShowVaccination2}
            Vaccinations={Vaccinations}
          /> 
        }
      </tbody>
    </table>
    <div className="paginations">
      {
        pages.length > 1 &&
        pages.map(page => ((
          <span 
            key={page} 
            className={page === Vaccinations.PageInfo.PageNumber ? 'active-page' : ''}
            onClick={() => updateDataUser(page)}
          >
            {page}
          </span>)))
      }
    </div>
    {/* <div className="plus-actions" onClick={() => setShowVaccination2(!showVaccination2)}>
        <img src={plusPNG} alt="" />
    </div> */}
    {
      (Vaccinations.Vaccination.length > 0 && !showVaccination2) &&
      <div className="plus-actions" onClick={() => setShowVaccination2(!showVaccination2)}>
        <img src={plusPNG} alt="" />
      </div>
    }
  </>
  )
}
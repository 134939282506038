import React from 'react'
import { Formik, Form } from 'formik'
// import Loader from '../Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga } from '../../redux/AC'
import * as Yup from 'yup'
import './InputModal.scss'
//components:
import { InputForm } from '../../components/InputElement/InputForm'

export const InputModal = ({fname, lname, mname, login, setIsModalInput}) => {

  const dispatch = useDispatch()
  const IsError = useSelector((state) => state.userReducer.IsError)
  const id = useSelector((state) => state.userReducer.id)

  return(
    <div className="inputmodal">
      <div className="inputmodal__body">
      <span className="close" onClick={() => setIsModalInput(false)}>&times;</span>
        <Formik
          initialValues={{
            fname: fname,
            lname: lname,
            mname: mname,
          }}
          validationSchema={Yup.object({
            fname: Yup.string().required('Поле не должно быть пустым'),
            lname: Yup.string().required('Поле не должно быть пустым'),
            mname: Yup.string().required('Поле не должно быть пустым')
          })}
          onSubmit = {(dataForm) => {
            console.log(dataForm)
            const values = {
              Login: login,
              UserRole: "User",
              FName: dataForm.fname,
              LName: dataForm.lname,
              MName: dataForm.mname,
              Vaccinations: {
                Vaccination: []
              },
              Tests: {
                Test: []
              }
            }
            console.log('values: ', values)
            dispatch(updateSaga(values))
            setIsModalInput(false)
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <InputForm
                label="Имя"
                id="fname"
                type="text"
                name="fname"
                placeholder="Имя..."
                required={true}
              />
              <InputForm
                label="Фамилия"
                id="lname"
                type="text"
                name="lname"
                placeholder="Фамилия..."
                required={true}
              />
              <InputForm
                label="Отчество"
                id="mname"
                type="text"
                name="mname"
                placeholder="Отчество..."
                required={true}
              />
              <div className="actions">
                {  isValid && dirty ?  <button className="btn btn-success">Сохранить</button> :
                  <button className="btn-disabled" disabled>Сохранить</button> 
                }
                <button 
                  className="btn btn-exit"
                  onClick={() => setIsModalInput(false)}
                >
                  Oтмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import './PCRTest.scss'
import { useDispatch, useSelector } from 'react-redux'
import checkedPNG from '../../assets/checked1.png'
import plusPNG from '../../assets/plus2x.png'
import { handleDate, getBase64, currentSelectedData } from '../../static/functions'
import { ScanModal } from '../../pages/ScanModal/ScanModal'
import { updateSaga, sagaDel, setIsDataSending } from '../../redux/AC'
import { PCRTest2 } from '../PCRTest2/PCRTest2'
import pencilPNG from '../../assets/pencilBlack1x.png'
import deletePNG from '../../assets/delete1x.png'
import { TRModalTest } from '../../pages/TRModalTest/TRModalTest'
import Loader from '../../components/Loader/Loader'

export const PCRTest = () => {

  const [isEditTR, setIsEdidTR] = useState(false)
  const [dataEdit, setDataEdit] = useState(null)

  const dispatch = useDispatch()

  const Login = useSelector((state) => state.userReducer.Login)
  const Tests = useSelector((state) => state.userReducer.Tests)
  const isDataSending = useSelector((state) => state.userReducer.isDataSending)

  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')
  const [showPCRTest2, setShowPCRTest2] = useState(false)

  const pages = []
  if (Tests !== null) {
    for (let i = 1; i <= Tests.PageInfo.TotalPages; i++) {
      pages.push(i)
    }
  }

  const showModalScan = (imgValue, id) => {
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }

  const updateDataUser = (numberPage = 1) => {
    dispatch(setIsDataSending(true))
    console.log(numberPage)

    const values = {
      Login,
      Tests: {
        Test: [],
        PageInfo: {
          PageNumber: numberPage,
        }
      },
      Vaccinations: {
        Vaccination: []
      }
    }
    dispatch(updateSaga(values))
  }

  const editTRData = (vacc) => {
    console.log(vacc.id)
    setIsEdidTR(true)
    setDataEdit(vacc)
  }

  const deleteValue = (id) => {
    dispatch(sagaDel({
      id,
      Login,
      TypeScan: "ScanTest" 
    }))
    updateDataUser()
  }

  return (
    <>
      { 
        isDataSending &&
        <div className="loader-container">
          <Loader />
        </div>
      }
      { isEditTR &&
        <TRModalTest
          dataEdit={dataEdit}
          setIsEdidTR={setIsEdidTR}
        />
      }
      {
        isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      <h2 className="title">ПЦР-ТЕСТЫ</h2>
      <table className="table-pcrtest">
      <thead>
        <tr>
          <th>Дата время записи</th>
          <th>Дата проведения теста на ПЦР</th>
          <th>Скан теста на ПЦР</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          Tests.Test.length > 0 ?
          Tests.Test.map(vacc => {
            return (
              <tr key={vacc.id}>
                <td>{vacc.Created}</td>
                <td>{vacc.DateTest}</td>
                <td className={vacc.HaveScan ? 'attached' : ''}>
                  { vacc.HaveScan ?
                    <img src={checkedPNG} alt="" onClick={() => showModalScan('getPcr', vacc.id)}/>
                    : 'Скан отсутсвует'
                  }
                </td>
                <td className="edit-td" >
                  <img src={pencilPNG} alt="" onClick={() => editTRData(vacc)}/>
                </td>
                <td className="delete-td">
                  <img src={deletePNG} alt="" onClick={() => deleteValue(vacc.id)}/>
                </td>
              </tr>
            )})
            : ////////////////////////////////////////////////////////////////////////////
              <PCRTest2
                handleDate={handleDate}
                currentSelectedData={currentSelectedData}
                getBase64={getBase64}
                Login={Login}
                dispatch={dispatch}
                updateSaga={updateSaga}
                setShowPCRTest2={setShowPCRTest2}
                Tests={Tests}            /> 
        }
      </tbody>
      {
        showPCRTest2 &&
        <PCRTest2
          handleDate={handleDate}
          currentSelectedData={currentSelectedData}
          getBase64={getBase64}
          Login={Login}
          dispatch={dispatch}
          updateSaga={updateSaga}
          setShowPCRTest2={setShowPCRTest2}
          Tests={Tests}
        /> 
        }
    </table>
    <div className="paginations">
      {
        pages.length > 1 &&
        pages.map(page => ((
          <span 
            key={page} 
            className={page === Tests.PageInfo.PageNumber ? 'active-page' : ''}
            onClick={() => updateDataUser(page)}
          >
            {page}
          </span>)))
      }
    </div>
    {
      (Tests.Test.length > 0 && !showPCRTest2) &&
      <div className="plus-actions" onClick={() => setShowPCRTest2(!showPCRTest2)}>
        <img src={plusPNG} alt="" />
      </div>
    }

  </>
  )
}
import { put, takeEvery, call } from 'redux-saga/effects'
import { gradientAPI } from '../../api/api'
import { setIsDataSending } from '../AC'
import { userConsts } from '../../static/consts'
import fileDownload from 'js-file-download' //!

function* listVaccWorker () {
  console.log('saga getListVacc')
  try {
    const response = yield call(gradientAPI.downloadListOfVaccinations)
    console.log(response)
    yield put (setIsDataSending(false))
    yield put (fileDownload(response.data, 'vacc.xlsx'))
  } catch (err) {
    console.log(err)
  }
}

export function* listVaccWotcher () {
  yield takeEvery(userConsts.LIST_SAGA_VACC, listVaccWorker) 
}
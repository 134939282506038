import React from 'react'
import { Formik, Form, Field } from 'formik'
// import Loader from '../Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga } from '../../redux/AC'
import * as Yup from 'yup'
import './BirthDayModal.scss'
//components:
import { InputForm } from '../../components/InputElement/InputForm'

export const BirthDayModal = ({bd, login, setIsModalBD}) => {

  const dispatch = useDispatch()

  return(
    <div className="birthdaymodal">
      <div className="birthdaymodal__body">
      <span className="close" onClick={() => setIsModalBD(false)}>&times;</span>
        <Formik
          initialValues={{
            birthday: bd,
          }}
          validationSchema={Yup.object({
            birthday: Yup.string().required('Поле не должно быть пустым'),
          })}
          onSubmit = {(dataForm) => {
            console.log(dataForm)
            const values = {
              Login: login,
              DateOfBirthDate: dataForm.birthday,
              Vaccinations: {
                Vaccination: []
              },
              Tests: {
                Test: []
              }
            }
            console.log('values: ', values)
            dispatch(updateSaga(values))
            setIsModalBD(false)
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              {/* <InputForm
                // label="Год рождения"
                id="birthday"
                type="text"
                name="birthday"
                placeholder="дд.мм.гггг"
                required={true}
              /> */}
              <Field 
                id="birthday"
                type="text"
                name="birthday"
                placeholder="дд.мм.гггг" 
              />
              <div className="actions">
                {  isValid && dirty ?  <button className="btn btn-success">Сохранить</button> :
                  <button className="btn-disabled" disabled>Сохранить</button> 
                }
                <button 
                  className="btn btn-exit"
                  onClick={() => setIsModalBD(false)}
                >
                  Oтмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}